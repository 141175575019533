<template>
  <div class="row q-ma-lg">
    <div 
      v-for="prop in properties" 
      :key="prop.id" 
      class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
      >
      <q-card
        flat
        class="
          items-center
          transparent
          q-ma-sm
          "
      >
        <!-- Card IMG background  -->
        <q-img
          :src="`${prop.attributes.logo.url}`"
          class="symple-corners prop-tile"
          spinner-color="accent"
        >
          <!-- Error  -->
          <template v-slot:error>
            <div class="absolute-full default-img text-muted">
              <q-card-section class="row items-center q-py-md q-px-lg">
                <!-- Publish icon conditionals -->
                <div v-if="prop.attributes.status === 'Published'">
                  <q-icon
                    name="fa fa-check-circle"
                    class="text-positive q-pr-xs publish-status"
                  />
                  <!-- Publish Status  -->
                  <span class="text-white publish-status">{{
                    prop.attributes.status
                  }}</span>
                </div>
                <div v-if="prop.attributes.status === 'Building'">
                  <q-icon
                    name="fa fa-check-circle"
                    class="text-warning q-pr-xs publish-status"
                  />
                  <!-- Publish Status  -->
                  <span class="text-white publish-status">{{
                    prop.attributes.status
                  }}</span>
                </div>
                <div v-if="prop.attributes.status === 'Processing'">
                  <q-icon
                    name="fa fa-clock"
                    class="text-warning q-pr-xs publish-status"
                  />
                  <!-- Publish Status  -->
                  <span class="text-white publish-status">{{
                    prop.attributes.status
                  }}</span>
                </div>
                <div v-if="prop.attributes.status === 'Not Published'">
                  <q-icon
                    name="fa fa-exclamation-circle"
                    class="text-warning q-pr-xs publish-status"
                  />
                  <!-- Publish Status  -->
                  <span class="text-white publish-status">{{
                    prop.attributes.status
                  }}</span>
                </div>
                <div v-if="prop.attributes.status == null">
                  <q-icon
                    name="fa fa-exclamation-circle"
                    class="text-negative q-pr-xs publish-status"
                  />
                  <span class="text-white publish-status">Publish Error</span>
                </div>

                <q-space />
                <!-- Tile Menu  -->
                <q-btn-dropdown
                  class="no-padding tile-dropdown"
                  color="transparent"
                  dropdown-icon="fa fa-ellipsis-v"
                  content-style="border-radius: 15px;"
                  id="property-dropdown"
                  for="property-dropdown"
                >
                  <q-list class="tile-dropdown-list">
                    <!-- View Property  -->
                    <q-item clickable v-close-popup @click="onItemClick">
                      <q-item-section class="justify-center">
                        <a target="_blank" :href="`${prop.attributes.site_url}`">
                          <q-item-label class="text-black"
                            >View
                            <i
                              class="q-ml-sm fa fa-link text-muted float-right"
                            ></i
                          ></q-item-label>
                        </a>
                      </q-item-section>
                    </q-item>
                    <!-- Edit Property  -->
                    <q-item clickable v-close-popup @click="onItemClick">
                      <q-item-section class="justify-center">
                        <router-link
                          :to="`/properties/${prop.attributes.access_id}/edit`"
                        >
                          <q-item-label class="text-black"
                            >Edit
                            <i
                              class="
                                q-ml-sm
                                fa fa-pencil-alt
                                text-muted
                                float-right
                              "
                            ></i
                          ></q-item-label>
                        </router-link>
                      </q-item-section>
                    </q-item>
                    <q-separator />
                    <!-- Duplicate Property  -->
                    <q-item
                      v-if="isAdmin"
                      clickable
                      v-close-popup
                      @click="duplicateProperty(prop)"
                    >
                      <q-item-section class="justify-center">
                        <q-item-label
                          >Duplicate
                          <i class="q-ml-sm fa fa-copy text-muted float-right"></i
                        ></q-item-label>
                      </q-item-section>
                    </q-item>
                    <!-- Access ID  -->
                    <q-item v-if="isAdmin" clickable v-close-popup>
                      <q-item-section class="justify-center">
                        <q-item-label
                          id="access-id-btn"
                          data-clipboard-target="#access-id"
                          >Access ID
                          <i
                            class="q-ml-sm fas fa-lock text-muted float-right"
                          ></i>
                          <q-tooltip id="access-id" class="bg-accent">
                            {{ prop.attributes.access_id }}
                          </q-tooltip>
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-btn-dropdown>
                <!-- End Tile Menu  -->
              </q-card-section>
              <!-- Property Title, Location, and Link to Edit  -->
            <router-link
              :to="`/properties/${prop.attributes.access_id}/edit`"
            >
              <div class="test-div q-pt-xl" style="height: 85%;">
              <!-- Property Title, Location, and Link to Edit  -->
                <q-card-section class="text-center q-pt-lg">
                  <div class="text-white text-h6 q-mt-lg">{{ prop.attributes.name }}</div>
                  <div class="text-muted text-city q-pt-sm">
                    {{ formatLocation(prop) }}
                  </div>    
                </q-card-section>
              </div>
            </router-link>
            </div>
          </template>
          <!-- Error END -->
          
          <div class="absolute-full no-border">
            <q-card-section class="row items-center q-py-md q-px-lg">
              <!-- Publish icon conditionals -->
              <div v-if="prop.attributes.status === 'Published'">
                <q-icon
                  name="fa fa-check-circle"
                  class="text-positive q-pr-xs publish-status"
                />
                <!-- Publish Status  -->
                <span class="text-white publish-status">{{
                  prop.attributes.status
                }}</span>
              </div>
              <div v-if="prop.attributes.status === 'Building'">
                <q-icon
                  name="fa fa-check-circle"
                  class="text-warning q-pr-xs publish-status"
                />
                <!-- Publish Status  -->
                <span class="text-white publish-status">{{
                  prop.attributes.status
                }}</span>
              </div>
              <div v-if="prop.attributes.status === 'Processing'">
                <q-icon
                  name="fa fa-clock"
                  class="text-warning q-pr-xs publish-status"
                />
                <!-- Publish Status  -->
                <span class="text-white publish-status">{{
                  prop.attributes.status
                }}</span>
              </div>
              <div v-if="prop.attributes.status === 'Not Published'">
                <q-icon
                  name="fa fa-exclamation-circle"
                  class="text-warning q-pr-xs publish-status"
                />
                <!-- Publish Status  -->
                <span class="text-white publish-status">{{
                  prop.attributes.status
                }}</span>
              </div>
              <div v-if="['Publish Error'].includes(prop.attributes.status)">
                <q-icon
                  name="fa fa-exclamation-circle"
                  class="text-negative q-pr-xs publish-status"
                />
                <!-- Publish Status  -->
                <span class="text-white publish-status">Publish Error</span>
              </div>

              <div v-if="['Failed', null].includes(prop.attributes.status)">
                <q-icon
                  name="fa fa-exclamation-circle"
                  class="text-negative q-pr-xs publish-status"
                />
                <!-- Publish Status  -->
                <span class="text-white publish-status">Failed</span>
              </div>

              <q-space />
              <!-- Tile Menu  -->
              <q-btn-dropdown
                class="no-padding tile-dropdown"
                color="transparent"
                dropdown-icon="fa fa-ellipsis-v"
                content-style="border-radius: 15px;"
              >
                <q-list class="tile-dropdown-list">
                  <!-- View Property  -->
                  <q-item clickable v-close-popup @click="onItemClick">
                    <q-item-section class="justify-center">
                      <a target="_blank" :href="`${prop.attributes.site_url}`">
                        <q-item-label class="text-black"
                          >View
                          <i
                            class="q-ml-sm fa fa-link text-muted float-right"
                          ></i
                        ></q-item-label>
                      </a>
                    </q-item-section>
                  </q-item>
                  <!-- Edit Property  -->
                  <q-item clickable v-close-popup @click="onItemClick">
                    <q-item-section class="justify-center">
                      <router-link
                        :to="`/properties/${prop.attributes.access_id}/edit`"
                      >
                        <q-item-label class="text-black"
                          >Edit
                          <i
                            class="
                              q-ml-sm
                              fa fa-pencil-alt
                              text-muted
                              float-right
                            "
                          ></i
                        ></q-item-label>
                      </router-link>
                    </q-item-section>
                  </q-item>
                  <q-separator />
                  <!-- Duplicate Property  -->
                  <q-item
                    v-if="isAdmin"
                    clickable
                    v-close-popup
                    @click="duplicateProperty(prop)"
                  >
                    <q-item-section class="justify-center">
                      <q-item-label
                        >Duplicate
                        <i class="q-ml-sm fa fa-copy text-muted float-right"></i
                      ></q-item-label>
                    </q-item-section>
                  </q-item>
                  <!-- Access ID  -->
                  <q-item v-if="isAdmin" clickable v-close-popup>
                    <q-item-section class="justify-center">
                      <q-item-label
                        id="access-id-btn"
                        data-clipboard-target="#access-id"
                        >Access ID
                        <i
                          class="q-ml-sm fas fa-lock text-muted float-right"
                        ></i>
                        <q-tooltip id="access-id" class="bg-accent">
                          {{ prop.attributes.access_id }}
                        </q-tooltip>
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                  <!-- Access Token  -->
                  <q-item v-if="isAdmin" clickable v-close-popup>
                    <q-item-section class="justify-center">
                      <q-item-label
                        id="access-token-btn"
                        data-clipboard-target="#access-token"
                        >Access Token
                        <i
                          class="q-ml-sm fas fa-lock text-muted float-right"
                        ></i>
                        <q-tooltip id="access-token" class="bg-accent">
                          {{ prop.attributes.access_token }}
                        </q-tooltip>
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
              <!-- End Tile Menu  -->
            </q-card-section>
            <!-- Property Title, Location, and Link to Edit  -->
          <router-link
              :to="`/properties/${prop.attributes.access_id}/edit`"
            >
              <div class="test-div q-pt-xl" style="height: 85%;">
              <!-- Property Title, Location, and Link to Edit  -->
                <q-card-section class="text-center q-pt-lg">
                  <div class="text-white text-h6 q-mt-lg ">{{ prop.attributes.name }}</div>
                  <div class="text-muted text-city q-pt-xs text-capitalize">
                    {{ formatLocation(prop) }}
                  </div>
                </q-card-section>
              </div>
            </router-link>
            <!-- End Title/Loc/Link  -->
          </div>
        </q-img>
        <!-- End Background IMG  -->
      </q-card>     
    </div>
  </div>
</template>

<style lang="scss" scoped>
.text-capitalize {
  text-transform: capitalize !important;
}
.q-item-label {
  line-height: normal !important;
}
.default-img {
  background-image: url('../../assets/images/img-default.png');
  background-size: cover;
  background-position: center;
}
.default-img:hover {
  background-image: url('../../assets/images/img-default.png');
  background-size: cover;
  background-position: center;
}
.q-img__content > div {
  padding: 0!important;
}
</style>

<script>
import axios from "axios";
import ClipboardJS from "clipboard";
import { computed } from "vue";
import { useStore } from "vuex";
import sympleApi from '@/api/symple-api'
import { slackNotifier } from '../helpers/helpers.js'

new ClipboardJS("#access-id-btn");
new ClipboardJS("#access-token-btn");

export default {
  name: "PropertyTile",
  props: ['connection', 'propertiesList'],
  setup() {
    const store = useStore()
    let isAdmin = computed(function () {
        return store.getters.isAdmin
    });

    return {
      isAdmin,
      store
    }
  },
  data() {
    return {
      properties: this.propertiesList,
      wsConnection: this.connection
    };
  },
  watch: {
    connection: function(val) {
      this.wsConnection = val
    },
    properties: function () {
      // let self = this;
      // var properties = JSON.parse(JSON.stringify(value))
      // this.wsConnection.onmessage = function(event) {
        // var data = JSON.parse(event.data)
        // if (data.type === 'Publish Status') { 
          // var property = properties.filter(x => x.attributes.access_id === data.access_id)[0]
          // self.$parent.$emit('handleWebhook', { message: JSON.parse(event.data), value: property.attributes })
        // }
      // }
    }
  },
  methods: {
    websocket() {
      if (process.env.NODE_ENV === 'development') {
        return "ws://localhost:8080";
      } else {
        return "wss://symple.io:8080";
      }
    },
    duplicateProperty(property) {
      axios
        .post(
          `${sympleApi.sympleApiBaseURL()}/properties/` +
            property.attributes.access_id +
            "/duplicate",
          "",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        )
        .then(() => {
          this.getProperties();
        })
        .catch((error) => {
          slackNotifier(error);
        });
    },
    deleteProperty(property) {
      axios
        .delete(
          `${sympleApi.sympleApiBaseURL()}/properties/` +
            property.attributes.access_id,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        )
        .then(() => {
          this.getProperties();
        })
        .catch((error) => {
          slackNotifier(error);
        });
    },
    formatLocation(property) {
      return property.attributes.city == undefined ? "" : property.attributes.city
    }
  },
};
</script>
