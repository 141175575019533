<template>
  <div>
    <q-card class="no-shadow q-ma-lg card-hover" style="border-radius: 18px; border: 1px solid #cacefa; overflow: hidden;" :id="uid" :data-field-id="input.id" :data-group="input.group">
      <q-card-section class="q-pt-none">
        <q-toolbar class="q-px-none">
          <!--<i class="fa fa-grip-lines handle"></i> -->
          <q-input 
            input-class="field-input" 
            v-model="input.name" 
            borderless
            :size="input.name.length + 1"
            dense
            class="mr-5"
            v-on:keyup="updateShortName()"
            @focus="setFocusedField()"
            @blur="clearFocusedField()"
          />
          <q-avatar size="28px" class="field-avatar" style="display: none;">
            <q-img src="https://content-api-1.s3.amazonaws.com/uploads/user/avatar_url/4/everton-webb.jpg" class="avatar-online" />
          </q-avatar>
          <q-space />
          <!-- short_name  should only show for Admins.-->
          <q-input 
            input-class="field-input field-input-sm" 
            v-model="input.short_name"
            v-if="isAdmin"
            borderless
            :size="input.short_name.length + 1"
            dense
            class="short-name q-pr-md text-grey"
            @keyup="changed()"
            @focus="setFocusedField()"
            @blur="clearFocusedField()"
          />
          <q-img
            v-if="isAdmin"
            src="../../assets/images/icons/icon-delete.svg"
            style="color: #cacfea; width: 20px; height: 20px;"
            @focus="setFocusedField()"
            @blur="clearFocusedField()"
            @click="deleteField = true"
          />
        </q-toolbar>

        <div class="row items-center">
          <q-img
            class="q-mb-lg preview-img"
            :src="inputImage"
            style="border: 1px solid #cacefa; border-radius: 5px"
            @change="changed()"
            @focus="setFocusedField()"
            @blur="clearFocusedField()"
          >
          </q-img>
          <div class="q-mb-xl">
            <!-- <label for="dropzone" class="input-label text-bold q-ml-md">Image</label>
            <DropZone class="q-ml-md" @drop.prevent="drop" @change="selectedFile"/>
            <div class="text-grey q-pl-md">{{ dropzoneFile.name }} <i class="fa fa-check-circle text-positive img-success" v-if="dropzoneFile"></i></div> -->
            <q-input
              @update:model-value="val => { file = val[0] }"
              dense
              borderless
              v-model="input.image"
              type="file"
              class="q-mt-sm q-mb-xs q-pl-xs text-muted input-space"
              id="imgUpload"
            />
            <div class="q-mt-md">
              <!-- Alt Text field for Uploaded Image -->
              <label for="altText" class="input-label text-bold q-ml-md">Alt Text</label>
              <q-input
                outlined
                v-model="input.alt_text"
                dense
                class="q-ml-md q-mt-sm"
                @change="changed()"
                @focus="setFocusedField()"
                @blur="clearFocusedField()"
              />
            </div>
          </div>
        </div>

      </q-card-section>
    </q-card>
    <!-- Delete Modal  -->
    <q-dialog v-model="deleteField" persistent>
      <q-card 
      class="q-pa-lg"
      style="border-radius: 18px; border: 1px solid #cacefa;">
        <q-card-section class="q-mb-lg">
          <div class="row justify-center q-mb-md">
            <span
              ><i class="fa fa-2x fa-exclamation-triangle text-negative"></i
            ></span>
          </div>
          <div class="row items center">
            <span class="q-ml-sm"
              >Are you sure you want to delete this field?
            </span>
          </div>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn outline label="Yes" color="positive" @click="removeFieldFunction()" />
          <q-btn label="No" color="negative" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <!-- end delete modal  -->
  </div>
</template>
<style lang="scss" scoped>
.preview-img {
  height: 40%!important;
  width: 40%!important;
}
.img-success {
  float: right;
  margin-top: 3px;
}
body.body--dark {
  .q-img {
    border: 1px solid #59576d !important;
  }
}
input[type="file"] {
  background: transparent !important;
}
</style>
<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
// import DropZone from "./DropZone.vue";

export default {
  name: "ImageField",
  // components: {
  //   DropZone
  // },
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    removeField: Function,
    group: String
  },
  emits: ['update:modelValue', 'setFocusedField', 'clearFocusedField', 'changed'],
  setup() {
    let dropzoneFile = ref("");
    const drop = (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
      this.changed()
    };
    const selectedFile = () => {
      dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
      this.changed()
    };
    const store = useStore()
    let isAdmin = computed(function () {
        return store.state.admin
    });
    return {
      isAdmin,
      store,
      deleteField: ref(false),
      drop,
      selectedFile,
      dropzoneFile,
    };
  },
  data() {
    return {
      uid: "",
    };
  },
  methods: {
    removeFieldFunction() {
      this.removeField(this.input)
      this.deleteField = false
    },
    updateShortName() {
      this.input.short_name = `${this.group.toLowerCase()} ${this.input.name.toLowerCase()}`.replace(/ /g, '_')
      this.changed()
    },
    changed() {
      this.$emit('changed', self)
    },
    setFocusedField() {
      this.$emit('setFocusedField', self)
    },
    clearFocusedField() {
      this.$emit('clearFocusedField', self)
    }
  },
  computed: {
    inputImage() {
      if (typeof(this.input.image) === 'string') {
        return this.input.image;
      } else if(this.input.image) {
        var file = this.input.image[0]
        return URL.createObjectURL(file);
      }
      else {
        return "";
      }
    },
    input: {
      get() {
        return this.modelValue
      },
      set(val) {
        if (this.input.alt_text === null ) {
          this.input.alt_text.String()
        }
        this.$emit('update:modelValue', val)
      }
    }
  },
};
</script>
