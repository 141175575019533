import { createStore } from 'vuex'
import axios from 'axios'
import sympleApi from '@/api/symple-api'

export const store = createStore({
  state() {
    return {
      admin: false,
      user: {},
      token: localStorage.getItem('authToken') || ''
    }
  },
  mutations: {
    auth_success(state, user){
      state.user = user
      if(user.attributes.is_admin === 1) {
        state.admin = true
      }
    },
    auth_error(state){
      state.user = {}
      state.token = ''
      localStorage.setItem("authToken", "")
    },
    logout(state){
      state.user = {};
      state.token = '';
    }
  },
  actions: {
    login({commit}, token){
      return new Promise((resolve, reject) => {
        axios
          .get(`${sympleApi.sympleApiBaseURL()}/users/current`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          })
          .then(resp => {
            const user = resp.data.data;
            commit('auth_success', user);
            resolve(resp);
          })
          .catch(err => {
            commit('auth_error');
            localStorage.removeItem('authToken');
            reject(err);
          });
      });
    },
    fetchUser({commit}){
      return axios
        .get(`${sympleApi.sympleApiBaseURL()}/users/current`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        })
        .then((response) => {
          if(response.status==200) {
            commit('auth_success', response.data.data)
            return response.data.data
          }
        })
        .catch(() => {
          commit('auth_error')
        })
    }
  },
  getters: {
    getToken: state => state.token,
    isLoggedIn: state => !!state.token,
    getUser: state => JSON.parse(JSON.stringify(state.user)),
    isAdmin: state => state.admin
  }
})