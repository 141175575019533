<template>
  <q-layout view="hHh lpr fFf">
    <!-- Header  -->
    <Header :connection="wsConnection" :active-users="users"/>
    <!-- End Header  -->
    <!-- Footer  -->
    <Footer />
    <!-- End Footer  -->
    <q-page-container>
      <q-page>
        <!-- page content -->
        <UserContainer />
      </q-page>
    </q-page-container>
  </q-layout>
</template>
<script>
window.$ = window.jQuery = window.jquery = require("jquery");
import Header from "../components/header/TheHeader.vue";
import Footer from "../components/footer/TheFooter.vue";
import UserContainer from "../components/user/UserContainer.vue";


export default {
  name: "Users",
  components: {
    UserContainer,
    Header,
    Footer,
  },
  props: ['activeUsers', 'connection', 'wsHandler'],
  data() {
    return {
      users: this.activeUsers,
      wsConnection: this.connection
    }
  }
};
</script>
