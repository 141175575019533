<template>
  <div
    class="
      symple-pattern
      window-height window-width
      row
      justify-center
      items-center
    "
  >
    <div class="col-xs-9 col-sm-6 col-lg-4 col-xl-4 slide-up-opacity">
      <LoginForm />
      <div class="text-center text-muted q-pb-md q-pt-md">
        ©2021 Dyverse Marketing Group, Inc.
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "../components/login/LoginForm.vue";

export default {
  name: "Login",
  components: {
    LoginForm,
  },
};
</script>

<style scoped>
.slide-up-opacity {
  opacity: 0;
  animation: slide-up-opacity 1s cubic-bezier(0.4, 0, 0.2, 1) 500ms forwards;
  transition-timing-function: ease-out;
}

@keyframes slide-up-opacity {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>