<template>
  <div class="q-ml-sm q-mr-lg alerts-spacing">
    <div class="row items-center no-wrap">
      <div class="text-center">
        <q-btn
          dense
          round
          class="transparent"
          icon="notifications"
          style="color: #00a3ff"
          size="12px"
        >
          <!-- <q-badge rounded floating color="negative" >2</q-badge> -->
        </q-btn>
      </div>
    </div>
    <!-- ACTUAL MENU   -->

    <!-- <q-menu style="border-radius: 24px !important;" class="shadow-7 ">
      <q-toolbar style="background: #f1f3f7">
        <q-title
          class="text-bold q-mx-auto p-px-none"
          style="color: #59576e; letter-spacing: 2px; font-size: 11px"
          >ALERTS</q-title
        >
      </q-toolbar>
      <q-list>
        <router-link to="#">
          <q-item v-for="alert in alertData" :key="alert.id" clickable v-close-popup @click="onItemClick" class="q-pa-md">
            <q-item-section side>
              <q-icon v-if="alert.attributes.notification_type===1" name="check_circle" color="green" />
              <q-icon v-if="alert.attributes.notification_type===2" name="info" color="amber" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Livingston</q-item-label>
              <q-item-label caption class="text-grey">Published 1 hour ago</q-item-label>
            </q-item-section>
          </q-item>
        </router-link>
        <q-separator />
        <router-link to="#">
          <q-item clickable v-close-popup @click="onItemClick" class="q-pa-md">
            <q-item-section side>
              <q-icon name="info" color="amber" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Mason Hall</q-item-label>
              <q-item-label caption class="text-grey">Saved 8 mins ago</q-item-label>
            </q-item-section>
          </q-item>
        </router-link>
      </q-list>
    </q-menu> -->
    <!-- END ACTUAL MENU  -->

    <!-- TEMP MENU  -->
    <q-menu style="border-radius: 24px !important; width: 350px !important;" class="shadow-7 alert-menu">
      <q-toolbar style="background: #f1f3f7;">
        <div
          class="text-bold q-mx-auto p-px-none menu-title collab-title"
          >NOTIFICATIONS</div
        >
      </q-toolbar>
      <q-list>
        <q-item class="text-center alert-text">
          <q-item-section class="text-center">
            <p class="q-mb-none">
            <q-icon name="far fa-bell" size="20px" class="q-mb-sm"/>
            <br />
              No new notifications!
            </p>
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
    <!-- END TEMP MENU  -->
  </div>
</template>

<style lang="scss" scoped>
.q-btn-dropdown__arrow {
  display: none !important;
}
.menu-title {
  color: #59576E;
}
.text-grey {
  color: #cacfea !important;
}
.q-list {
  min-width: 350px;
}
.alert-text {
   padding: 13px 20px;
   color: #7f7d90;
   p {
    font-size: 14px;
   }
}
@media only screen and (max-width: 40em) {
  .q-list {
    min-width: 250px;
  }
  .alert-text {
    padding: 13px 15px;
    p {
      font-size: 11px;
      line-height: 13px;
    }
  }
    .alert-menu {
      min-width: 240px !important;
    }
  } /* max-width 640px, mobile-only styles, use when QAing mobile issues */
</style>

<script>
export default {
  setup() {
    return {
      onItemClick() {
        // console.log('Clicked on an Item')
      },
    };
  },
};
</script>
