<template>
  <q-page-container>

    <!-- ADD SPECIAL FORM START -->
    <q-drawer 
      v-model="rightDrawerOpen" 
      side="right" 
      overlay 
      class="edit-left-drawer"
      style="z-index: 100 !important;">
      <q-scroll-area class="fit">
      <q-item-section class="q-pt-md q-pl-md q-item-special-form">
        <q-toolbar class="q-pl-none">
          <span class="text-h6 text-left q-pb-xs">Add Special</span>
          <q-space />
          <q-icon
            @click="toggleRightDrawer"
            class="q-pb-xl q-pl-lg"
            name="fas fa-times"
            style="color: #c1c1c1"
            size="20px"
          ></q-icon>
        </q-toolbar>
      </q-item-section>
      <!-- Add Special Form  -->
      <q-item-section class="q-pa-md q-item-special-form">
        <add-special :properties="propertySelect" :groups="groupSelect" @updateSpecials="getSpecials" @closeAdd="toggleRightDrawer" @updateGroupsParams="updateSelectedProperties"></add-special>
      </q-item-section>
      </q-scroll-area>
    </q-drawer>

    <q-page 
      class="content q-px-md"
      id='specials-table'
      >
      <q-toolbar class="filter-add-icon">
          <input-filter></input-filter>
          <q-space />
          <q-btn
            rounded
            @click="toggleRightDrawer"
            color="primary"
            class="text-bold add-special-btn q-px-md"
          >
          <i class="fas fa-plus q-mr-sm"></i>
          Special
          </q-btn>
        </q-toolbar>

      <!-- Special Data  -->
      <special-data :specials="specialData" :properties="propertySelect" :groups="groupSelect" @updateSpecials="getSpecials" @closeAdd="toggleRightDrawer" @updateGroupsParams="updateSelectedProperties"></special-data>
      <!-- End special data  -->
    </q-page>

    <!-- Special Data  -->
  </q-page-container>
</template>

<style lang="scss" scoped>
  .rounded-corners {
    border-radius: 16px;
  }
  .search-bar {
  border: 1px solid #cacfea;
  border-radius: 25px;
}
</style>

<script>
import InputFilter from "../inputs/InputFilter.vue"
import SpecialData from "./SpecialData.vue";
import AddSpecial from "./AddSpecial.vue";
import axios from "axios";
import { ref } from "vue";
import sympleApi from '@/api/symple-api'
import { slackNotifier } from '../helpers/helpers.js'

export default {
  components: { SpecialData, AddSpecial, InputFilter },
  setup() {
    const rightDrawerOpen = ref(false);
    return {
      rightDrawerOpen,
      toggleRightDrawer() {
        rightDrawerOpen.value = !rightDrawerOpen.value;
      }
    };
  
  },
  created() {
    this.getProperties();
    this.getSpecials();
  },
  data() {
    return {
      specialData: [],
      propertySelect: [],
      selectedProperties: [],
      groupSelect: []
    };
  },
  methods: {
    getSpecials() {
      axios
        .get(`${sympleApi.sympleApiBaseURL()}/specials`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => {
          this.specialData = response.data.data;
        })
        .catch((error) => {
          slackNotifier(error);
        });
    },
    getProperties() {
      axios
        .get(`${sympleApi.sympleApiBaseURL()}/properties`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => {
          this.propertySelect = response.data.data.map(property => {
            return {
              label: property.attributes.name,
              value: property.id
            }
          })
        })
        .catch((error) => {
          slackNotifier(error);
        });
    },
    getGroups() {
      axios
        .get(`${sympleApi.sympleApiBaseURL()}/groups?`+this.groupsParams(), {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => {
          this.groupSelect = response.data.groups
          if(this.groupSelect.includes("Footer")){
            this.groupSelect.splice(this.groupSelect.indexOf("Footer"), 1);
          }
          if(this.groupSelect.includes("Special")){
            this.groupSelect.splice(this.groupSelect.indexOf("Special"), 1);
          }
        })
        .catch((error) => {
          slackNotifier(error);
        });
    },
    updateSelectedProperties(value) {
      this.selectedProperties=value.map(prop => prop.value)
      this.getGroups()
    },
    groupsParams() {
      let string = ""
      for(let i=0;i<this.selectedProperties.length;i++)
      {
        string = string.concat("property_ids[]="+this.selectedProperties[i]+"&")
      }
      return string
    },
    specialsFilter() {
      console.log('specials-filter-input');
}
  },
  
};
</script>
