<template>
  <q-layout view="hHh lpr fFf">
    <!-- Header  -->
    <Header :connection="wsConnection" :active-users="users"/>
    <!-- End Header  -->
    <!-- Footer  -->
    <Footer />
    <!-- End Footer  -->
    <q-page-container>
      <q-page>
        <!-- page content -->
        <settings-form :user="user"></settings-form>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import axios from 'axios';
import { slackNotifier } from '../components/helpers/helpers.js';
import Header from "../components/header/TheHeader.vue";
import Footer from "../components/footer/TheFooter.vue";
import SettingsForm from "../components/settings/SettingsForm.vue";
import sympleApi from '@/api/symple-api'

export default {
  name: "Properties",
  components: {
    Header,
    Footer,
    SettingsForm,
  },
  props: ['activeUsers', 'connection', 'wsHandler'],
  created() {
    this.getUser();
  },
  data() {
    return {
      user: [],
      users: this.activeUsers,
      wsConnection: this.connection
    };
  },
  methods: {
    getUser() {
      axios
        .get(`${sympleApi.sympleApiBaseURL()}/users/current/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => {
          this.user = response.data.data
        })
        .catch((error) => {
          slackNotifier(error);
        })
    }
  }
};
</script>