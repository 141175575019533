<template>
  <q-footer 
    borderless 
    class="bg-white text-white gt-sm symple-footer" 
    style="
      border-top: 2px solid #cacefa; 
      padding-bottom: -15px !important;
      "
      >
    <q-toolbar class="justify-center items-center">
      <div class="col-6-lg text-light">
        Symple v1.0.0&nbsp;&nbsp;|&nbsp;&nbsp;©<span>2021</span>
      </div>

      <q-space />

      <div class="col-6-lg">
        <span class="text-light">Crafted with </span>
        <q-icon name="fas fa-bolt text-warning"></q-icon> <span class="text-light">by </span>
        <a href="https://dyverse.com" target="_blank" class="text-light text-bold">Dyverse</a>
      </div>
    </q-toolbar>      
  </q-footer>
</template>

<style lang="scss" scoped>
.text-light {
  color: #cacefa !important;
}
</style>

<script>
export default {
  name: "Footer"
}
</script>
