<template>
    <q-form
      @submit="userEdit"
      @reset="onReset"
      class="q-gutter-md q-pt-md"
    >
      <!-- First Name -->
      <label class="input-label text-bold text-grey">First Name</label>
      <q-input
        dense
        outlined
        v-model="userData.first_name"
        class="q-mt-xs q-mb-md"
        style="border-radius: 5px;"
      />
      <!-- Last Name -->
      <label class="input-label text-bold text-grey">Last Name</label>
      <q-input
        dense
        outlined
        v-model="userData.last_name"
        class="q-mt-xs q-mb-md"
        style="border-radius: 5px;"
      />
      <!-- Company -->
      <label class="input-label text-bold text-grey">Company</label>
      <q-input
        dense
        outlined
        v-model="userData.company"
        class="q-mt-xs q-mb-md"
        style="border-radius: 5px;"
      />
      <!-- Email -->
      <label class="input-label text-bold text-grey">Email</label>
      <q-input
        dense
        outlined
        v-model="userData.email"
        class="q-mt-xs q-mb-md"
        style="border-radius: 5px;"
      />
      <!-- Password -->
      <label class="input-label text-bold text-grey">Password</label>
      <q-input
        dense
        outlined
        v-model="userData.password"
        class="q-mt-xs q-mb-md"
        style="border-radius: 5px;"
      />
      <!-- User Group -->
      <label class="input-label text-bold text-grey">User Group</label>
      <q-select
        dense
        outlined
        v-model="userData.is_admin"
        :options="options"
        class="q-mt-xs q-mb-md"
        style="border-radius: 5px;"
      ></q-select>

      <!-- Submit Btn  -->
      <q-item-section class="q-pa-md border-bottom">
        <q-btn
          color="positive"
          text-color="white"
          unelevated
          label="Save"
          type="submit"
          class="q-mb-sm text-weight-bold"
          style="border-radius: 50px !important"
        />
      </q-item-section>
    </q-form>
</template>
<script>
import axios from "axios";
import { ref } from "vue";
import { Notify } from 'quasar'
import sympleApi from '@/api/symple-api'
import { slackNotifier } from '../helpers/helpers.js'

export default {
  name: "EditUser",
  props: ['user'],
  data() {
    return {
      options: ["Client", "Admin"],
      userData: {
        id: '',
        first_name: '',
        last_name: '',
        company: '',
        email: '',// Required
        password: '', // Required
        is_admin: ref(null)
      }
    }
  },
  watch:{    
    user() {
      this.loadUserData();
    }
  },
  methods: {
    editUser(){
      axios
        .put(`${sympleApi.sympleApiBaseURL()}/users/`+this.userData.id, this.userDataToParams, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then(() => {
          this.$emit('closeEdit');
          this.onReset();
          this.$emit('updateUsers');
          //Refresh special data
        })
        .catch((error) => {
          slackNotifier(error);
        });
    },
    userEdit() {
      try {
        this.editUser()
        Notify.create({
          type: 'positive',
          message: 'Saved!',
          timeout: 1000,
          color: 'positive'
        })
      } catch(error) {
        slackNotifier(error)
        Notify.create({
          type: 'negative',
          message: 'An error occurred while saving!',
          timeout: 1000,
          color: 'negative'
        })
      }
    },
    loadUserData(){
      Object.keys(this.userData).forEach((key) => {
        this.userData[key] = this.user.attributes[key]
      });
      this.userData.id = this.user.id
      this.userData.is_admin===0 ? this.userData.is_admin="Client" : this.userData.is_admin="Admin"
    },
    onReset(){
      Object.assign(this.$data, this.$options.data.call(this));
      //Reset the form
    },
  },
  computed: {
    userDataToParams() {
      let params = JSON.parse(JSON.stringify(this.userData));
      params.is_admin==="Admin" ? params.is_admin=1 : params.is_admin=0
      return params
    }
  }
};
</script>
