<template>  
  <q-form @submit="submitEdit" class="q-gutter-md">
    <!-- Header -->
    <label class="input-label text-bold text-grey">Header</label>
    <q-input
      dense
      outlined
      v-if="specialData.attributes"
      v-model="specialData.attributes.header"
      class="q-mt-xs q-mb-md"
      style="border-radius: 5px;"
    />
    <!-- End Header  -->
    <!-- Description  -->
    <label class="input-label text-bold text-grey">Description</label>
    <q-input
      dense
      v-if="specialData.attributes"
      v-model="specialData.attributes.description"
      outlined
      type="textarea"
      style="border-radius: 5px;"
      class="q-mt-xs q-mb-md"
    />
    <!-- End Description -->
    <!-- Date Range   -->
    <label class="input-label text-bold text-grey">Start Date</label>
    <q-input
      class="q-mt-xs q-mb-md"
      dense
      outlined
      v-if="specialData.attributes"
      v-model="specialData.attributes.start_date"
      mask="MM/DD/YYYY"
      type="date"
      range
      style="border-radius: 5px;"
    ></q-input>
    <label class="input-label text-bold text-grey">End Date</label>
    <q-input
      class="q-mt-xs q-mb-md"
      dense
      outlined
      v-if="specialData.attributes"
      v-model="specialData.attributes.end_date"
      mask="MM/DD/YYYY"
      type="date"
      range
      style="border-radius: 5px;"
    ></q-input>
    <!-- End Date Range  -->
    <!-- Assign Properties  -->
    <label for="modelAddUnique" class="input-label text-bold text-grey"
      >Assign Properties</label
    >
    <q-select
      v-if="specialData.attributes"
      dense
      outlined
      use-chips
      use-input
      multiple
      map-options
      hide-dropdown-icon
      new-value-mode="edit-unique"
      v-model="selectedProperties"
      input-debounce="0"
      :options="propertySelect"
      style="border-radius: 5px;;"
      class="q-mt-sm q-mb-md"
      for="assign-property-input-edit"
      v-on:keyup="assignPropertyFilterEdit"
      v-on:update:modelValue="updateParams"
    />
    <!-- End Assign Properties  -->
    <!-- Assign Groups  -->
    <label for="modelAddUnique" class="input-label text-bold text-grey"
      >Assign Groups</label
    >

    <q-select
      v-if="specialData.attributes"
      dense
      outlined
      use-chips
      use-input
      multiple
      hide-dropdown-icon
      new-value-mode="add-unique"
      v-model="specialData.attributes.groups"
      input-debounce="0"
      :options="groupSelect"
      style="border-radius: 5px;"
      class="q-mt-sm"
      for="assign-group-input-edit"
      v-on:keyup="assignGroupFilterEdit"
      behavior="menu"
    />
    <!-- End Assign Groups  -->
    <!-- Submit Btn  -->
    <q-item-section class="q-pa-md border-bottom">
      <q-btn
        color="positive"
        text-color="white"
        unelevated
        label="Save"
        type="submit"
        class="q-mb-sm text-weight-bold"
        style="border-radius: 50px !important"
      />
    </q-item-section>
  </q-form>
</template>
<style >
.q-menu #qvs_2 {
  max-height: 150px!important;
}
</style>
<script>
import axios from "axios";
import { date } from "quasar"
import { ref } from "vue";
import { Notify } from 'quasar'
import sympleApi from '@/api/symple-api'
import { slackNotifier } from '../helpers/helpers.js'

export default {
  name: "EditSpecial",
  props: ['properties', 'special', 'groups'],
  data() {
    // Add User Form
    return {
      propertySelect: [],
      groupSelect: [],
      selectedProperties: ref([]),
      specialData: []
    }
  },
  watch:{    
    properties() {
      this.propertySelect = this.properties;
    },
    groups() {
      this.groupSelect = this.groups;
    },
    special() {
      this.specialData = this.special;
      this.selectedProperties = Object.values(this.specialData.attributes.properties).map(property => {
        return { 
          label: property.name,
          value: property.id
        }
      })
    }
  },
  methods: {
    editSpecial(){
      this.$emit('closeAdd');
      var start_date = new Date(Date.parse(this.specialData.attributes.start_date))
      var end_date = new Date(Date.parse(this.specialData.attributes.end_date))
      start_date.setMinutes(start_date.getMinutes()+start_date.getTimezoneOffset())
      end_date.setMinutes(end_date.getMinutes()+end_date.getTimezoneOffset())
      end_date.setHours(23,59,59,999);
      this.specialData.attributes.start_date = date.formatDate(start_date, 'YYYY-MM-DD')
      this.specialData.attributes.end_date = date.formatDate(end_date,'YYYY-MM-DD')
      this.specialData.attributes.property_ids = this.selectedProperties.map(property => parseInt(property.value));
      axios
        .put(`${sympleApi.sympleApiBaseURL()}/specials/` + this.specialData.id, this.specialData.attributes, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then(() => {
          this.$emit('updateSpecials');
          this.$emit('closeEdit');
          //Refresh special data
        })
        .catch((error) => {
          slackNotifier(error);
        });
    },
    submitEdit() {
      try {
        this.editSpecial()
        Notify.create({
          type: 'positive',
          message: 'Saved!',
          timeout: 1000,
          color: 'positive'
        })
      } catch(error) {
        slackNotifier(error)
        Notify.create({
          type: 'negative',
          message: 'An error occurred while saving!',
          timeout: 1000,
          color: 'negative'
        })
      }
    },
    updateParams(value) {
      this.$emit("updateGroupsParams", value)
    },
    assignPropertyFilterEdit() {
      var td, i, txtValue;
      var input = document.getElementById("assign-property-input-edit");
      var filter = input.value.toUpperCase();
      var table = document.getElementById("qvs_4");
      var tr = table.getElementsByClassName("q-item");

      // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("span")[0];

        if (td) {
          txtValue = td.textContent || td.innerText;
          if (
            txtValue.toUpperCase().indexOf(filter) > -1 
          ) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    },
    assignGroupFilterEdit() {
      var td, i, txtValue;
      var input = document.getElementById("assign-group-input-edit");
      var filter = input.value.toUpperCase();
      var table = document.getElementById("qvs_3");
      var tr = table.getElementsByClassName("q-item");

      // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("span")[0];

        if (td) {
          txtValue = td.textContent || td.innerText;
          if (
            txtValue.toUpperCase().indexOf(filter) > -1 
          ) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    },
  }
};
</script>
