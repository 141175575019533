<template>
    <div class="symple-purple-gradient window-height window-width row justify-center items-center">
        <PasswordReset />
    </div>
</template>

<script>
import PasswordReset from "../components/login/PasswordReset.vue"

export default {
    name: "Forgot",
    components: {
        PasswordReset
    }
}
</script>