<template>
  <div>
    <q-card
      class="no-shadow q-ma-lg card-hover"
      style="border-radius: 18px; border: 1px solid #cacefa;"
      :id="uid"
      :data-field-id="input.id"
      :data-group="input.group"
    >
      <q-card-section class="q-pt-none">
        <q-toolbar class="q-px-none">
          <!--<i class="fa fa-grip-lines handle"></i> -->
          <q-input 
            input-class="field-input" 
            v-model="input.name" 
            borderless
            :size="input.name.length + 1"
            dense
            class="mr-5"
            v-on:keyup="updateShortName()"
            @focus="setFocusedField()"
            @blur="clearFocusedField()"
          />
          <q-avatar size="28px" class="field-avatar" style="display: none;">
            <q-img src="https://content-api-1.s3.amazonaws.com/uploads/user/avatar_url/4/everton-webb.jpg" class="avatar-online" />
          </q-avatar>
          <q-space />
          <!-- short_name  should only show for Admins.-->
          <q-input 
            input-class="field-input field-input-sm" 
            v-model="input.short_name"
            v-if="isAdmin"
            borderless
            :size="input.short_name.length + 1"
            dense
            class="short-name q-pr-md text-grey"
            @keyup="changed()"
            @focus="setFocusedField()"
            @blur="clearFocusedField()"
          />
          <q-img
            v-if="isAdmin"
            src="../../assets/images/icons/icon-delete.svg"
            style="color: #cacfea; width: 20px; height: 20px"
            @click="deleteField = true"
          />
        </q-toolbar>
        <!-- <q-input borderless v-model="text" dense style="border-radius: 5px; border: 1px solid #cacefa" /> -->
        <q-editor
          v-model="input.data"
          flat
          style="border-radius: 5px; border: 1px solid #cacefa"
          toolbar-text-color="info"
          toolbar-toggle-color="secondary"
          toolbar-bg="grey-2"
          :toolbar="[
            ['bold', 'italic', 'underline'],
            [
              {
                label: $q.lang.editor.formatting,
                icon: $q.iconSet.editor.formatting,
                list: 'no-icons',
                options: ['p', 'h3', 'h4', 'h5', 'h6'],
              },
            ],
          ]"
          @keyup="changed()"
          @focus="setFocusedField()"
          @blur="clearFocusedField()"
        >
        </q-editor>
      </q-card-section>
    </q-card>
<!-- Delete Modal  -->
    <q-dialog v-model="deleteField" persistent>
      <q-card 
      class="q-pa-lg"
      style="border-radius: 18px; border: 1px solid #cacefa;">
        <q-card-section class="q-mb-lg">
          <div class="row justify-center q-mb-md">
            <span
              ><i class="fa fa-2x fa-exclamation-triangle text-negative"></i
            ></span>
          </div>
          <div class="row items center">
            <span class="q-ml-sm"
              >Are you sure you want to delete this field?
            </span>
          </div>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn outline label="Yes" color="positive" @click="removeFieldFunction()" />
          <q-btn label="No" color="negative" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <!-- end delete modal  -->
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "RichText",
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    removeField: Function,
    group: String
  },
  emits: ['update:modelValue', 'setFocusedField', 'clearFocusedField', 'changed'],
  setup() {
    const store = useStore()
    let isAdmin = computed(function () {
        return store.state.admin
    });
    return {
      isAdmin,
      store,
      deleteField: ref(false),
    };
  },
  data() {
    return {
      uid: "",
    };
  },
  methods: {
    removeFieldFunction() {
      this.removeField(this.input)
      this.deleteField = false
    },
    updateShortName() {
      this.input.short_name = `${this.group.toLowerCase()} ${this.input.name.toLowerCase()}`.replace(/ /g, '_')
      this.changed()
    },
    changed() {
      this.$emit('changed', self)
    },
    setFocusedField() {
      this.$emit('setFocusedField', self)
    },
    clearFocusedField() {
      this.$emit('clearFocusedField', self)
    }
  },
  computed: {
    input: {
      get() {
          return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  }
};
</script>