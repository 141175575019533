<template>
  <div class="q-pt-xs q-pr-md users-spacing">
    <div class="row items-center no-wrap">
      <div class="text-center">
        <div class="avatar-menu relative-position q-pa-sm">
          <q-avatar v-for="user in activeUsersSortedByColor" :key="user.image" size="32px" style="margin-right: -6px">
            <q-img :src="user.image" class="avatar-online" :style="colorBorder(user.color)" />
          </q-avatar>
          <q-icon class="position-relative add-user-icon q-ml-md q-mr-xs text-center" style="top: 4px;">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 512.012 512.012" style="enable-background:new 0 0 512.012 512.012;" xml:space="preserve">
            <g>
              <g>
                <path d="M505.325,129.52c-9.241-9.802-24.699-10.167-34.452-0.951l-214.88,203.566L41.138,128.57
                  c-9.753-9.241-25.187-8.851-34.452,0.951c-9.265,9.777-8.851,25.211,0.927,34.476l231.63,219.439
                  c4.681,4.438,10.728,6.681,16.75,6.681c6.022,0,12.093-2.243,16.775-6.681l231.63-219.439
                  C514.176,154.731,514.59,139.298,505.325,129.52z"/>
              </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            </svg>
          </q-icon>
        </div>
      </div>
    </div>

    <q-menu
      id="userDropdown"
      style="border-radius: 24px !important; width: 350px; height: 365px;"
      class="shadow-7"
    >
      <q-list>
        <q-toolbar class="collab-header">
          <div
            class="text-bold q-pa-sm collab-title"
            >COLLABORATORS</div
          >
        </q-toolbar>
        <div class="q-px-lg q-py-md">
          <div v-for="(user, index) in portfolioUsersSortedByActive" :key="user.id" >
            <router-link :to="userPath(user.avatar_url.url)">
              <q-item v-close-popup class="q-pa-sm">
                <q-item-section side  >
                  <q-avatar rounded size="50px" >
                    <q-img :src="user.avatar_url.url" class='avatar-online' />
                    <q-badge floating rounded v-if="userActive(user.avatar_url.url)"><i class="fas fa-circle fa-md q-icon text-positive"></i></q-badge>
                    <q-badge floating rounded v-else><i class="fas fa-circle fa-md q-icon text-grey"></i></q-badge>
                  </q-avatar>

                </q-item-section>
                <q-item-section class="user-option">
                  <span class="user-name text-bold text-primary">{{ user.email }}</span>
                  <span class="user-status text-caption text-grey">{{ userStatus(user.avatar_url.url) }}</span>
                </q-item-section>
              </q-item>
            </router-link>
            <q-separator v-if="index != portfolioUsersSortedByActive.length - 1" />
          </div>
        </div>
      </q-list>
    </q-menu>
  </div>
</template>

<style lang="scss" scoped>
.avatar-menu {
  background: transparent;
  border-radius: 30px;
}
.avatar-menu:hover {
  background: #3c236f69;
}
.avatar-online {
  border-radius: 24px;
}
.avatar-offline {
  border: 3px solid #7e8090;
  border-radius: 24px;
}
#userDropdown {
  .avatar-online {
    border: none;
  }
  .avatar-offline {
    border: none;
  }
  .q-badge--floating {
    background-color: rgb(255, 255, 255);
    padding: 1px;
    position: absolute;
    top: 37px;
    right: 0px;
  }
}
.add-user-icon {
  color:rgb(202, 207, 234, .6) !important;
}
.user-option:hover {
  .user-name {
    color: #351E65 !important;
  }
  .user-status {
  font-style: normal;
  color: #686A78;
}
}
.collab-header {
  background: #f1f3f7;
  .collab-title {
    color: #59576E;
    letter-spacing: 2px;
  }
}
.collab-menu {
  border-radius: 24px !important;
}
.text-grey {
  color: #cacfea !important;
}
.q-list {
  min-width: 20vw;
}
.q-menu {
  background: #fff !important;
  width: 320px;
}
</style>

<script>
export default {
  props: ['portfolio', 'activeUsers'],
  data() {
    return {
      users: this.activeUsers,
      userPortfolio: this.portfolio
    }
  },
  computed: {
    portfolioUsers() {
      return JSON.parse(JSON.stringify(this.userPortfolio.attributes.users))
    },
    portfolioUsersSortedByActive() {
      let self = this;
      let users = this.portfolioUsers;
      users = users.sort(function(a, b) {
        var item1 = self.userActive(a.avatar_url.url) // ignore upper and lowercase
        var item2 = self.userActive(b.avatar_url.url) // ignore upper and lowercase
        return item2 - item1;
      });
      return users
    },
    activeUsersSortedByColor() {
      let self = this;
      let users = this.users;
      users = users.sort(function(a, b) {
        var item1 = self.hexToHSL(a.color)
        var item2 = self.hexToHSL(b.color)
        return item2 - item1;
      })
      return users
    }
  },
  methods: {
    userStatus(image) {
      if (this.userActive(image)) {
        let user = this.users.filter(e => e.image === image)[0]
        return user.status
      } else {
        return 'Offline';
      }
    },
    userPath(image) {
      if (this.userActive(image)) {
        let user = this.users.filter(e => e.image === image)[0]
        return user.path
      } else {
        return '#';
      }
    },
    websocket() {
      if (process.env.NODE_ENV === 'development') {
        return "ws://localhost:8080";
      } else {
        return "wss://symple.io:8080";
      }
    },
    colorBorder(color) {
      return `border: 3px solid ${color};`
    },
    userActive(image) {
      return this.users.some(e => e.image === image)
    },
    hexToHSL(H) {
      // Convert hex to RGB first
      let r = 0, g = 0, b = 0;
      if (H.length == 4) {
        r = "0x" + H[1] + H[1];
        g = "0x" + H[2] + H[2];
        b = "0x" + H[3] + H[3];
      } else if (H.length == 7) {
        r = "0x" + H[1] + H[2];
        g = "0x" + H[3] + H[4];
        b = "0x" + H[5] + H[6];
      }
      // Then to HSL
      r /= 255;
      g /= 255;
      b /= 255;
      let cmin = Math.min(r,g,b),
          cmax = Math.max(r,g,b),
          delta = cmax - cmin,
          h = 0,
          s = 0,
          l = 0;

      if (delta == 0)
        h = 0;
      else if (cmax == r)
        h = ((g - b) / delta) % 6;
      else if (cmax == g)
        h = (b - r) / delta + 2;
      else
        h = (r - g) / delta + 4;

      h = Math.round(h * 60);

      if (h < 0)
        h += 360;

      l = (cmax + cmin) / 2;
      s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
      s = +(s * 100).toFixed(1);
      l = +(l * 100).toFixed(1);

      return h + s + l;
    }
  }
};
</script>
