<template>
    <div class="symple-purple-gradient window-height window-width row justify-center items-center">
        <ForgotPassword />
    </div>
</template>

<script>
import ForgotPassword from "../components/login/ForgotPassword.vue"

export default {
    name: "Forgot",
    components: {
        ForgotPassword
    }
}
</script>