import axios from "axios";

export const slackNotifier = function(message) {
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'localhost')
  {
    console.log(message)
  }
  else {
    try {
      axios
        .post(
          "https://hooks.slack.com/services/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",
          JSON.stringify({
            text: message
          })
        )
    } catch (error) {
      console.log(error)
    }
  }
}