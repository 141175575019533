<template>
  <q-avatar @click="menuToggleCheck" size="31px" style="border-radius: 0px">
    <div id="nav-icon" class="q-mt-xs" @click="showing = true">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <q-menu
      fit
      style="border-radius: 24px !important"
      class="shadow-7"
      id="account-menu"
      auto-close
    >
      <q-list style="min-width: 300px">
        <div class="column items-center q-pb-md bg-grey-2 account-menu-top">
          <q-avatar class="q-mt-md q-mb-xs" size="70px">
            <img :src="currentUser.attributes.avatar_url.url" />
          </q-avatar>
          <div>
            <span class="text-weight-bold q-pr-xs"
              >{{ currentUser.attributes.first_name }}
              {{ currentUser.attributes.last_name }}</span
            >
            <q-badge
              transparent
              v-if="currentUser.attributes.is_admin"
              align="middle"
              color="positive"
            >
              admin
            </q-badge>
          </div>
          <div class="text-subtitle3">{{ currentUser.attributes.email }}</div>
        </div>
        <q-item clickable class="q-px-md" to="/properties" @click="removeOverlay">
          <q-item-section class="text-weight-medium justify-center">
            <q-item-label>
              Sites
              <i class="q-ml-sm fa fa-th-large text-muted float-right"></i>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item
          clickable
          class="q-px-md"
          style="padding-right: 14px"
          to="/specials"
        >
          <q-item-section class="text-weight-medium justify-center" @click="removeOverlay">
            <q-item-label>
              Specials
              <i class="q-ml-sm fas fa-tags text-muted float-right"></i>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item
          clickable
          class="q-pl-md"
          style="padding-right: 14px"
          to="/settings"
          @click="removeOverlay"
        >
          <q-item-section class="text-weight-medium justify-center">
            <q-item-label>
              Profile
              <i class="q-ml-sm fas fa-user-cog text-muted float-right"></i>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item
          clickable
          class="q-pl-md"
          style="padding-right: 15px"
          @click="toggleDark"
        >
          <q-item-section class="text-weight-medium justify-center">
            <q-item-label>
              Theme
              <i
                id="toggle-off"
                class="q-ml-sm fas fa-toggle-off text-muted float-right"
                style="font-size: 15.5px"
              >
              </i>
              <i
                id="toggle-on"
                class="q-ml-sm fas fa-toggle-on text-muted float-right"
                style="font-size: 15.5px"
              >
              </i>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-separator v-if="isAdmin" />
        <q-item
          v-if="isAdmin"
          clickable
          class="q-pl-md"
          style="padding-right: 17px"
          @click="createSite; removeOverlay"
        >
          <q-item-section class="text-weight-medium justify-center">
            <q-item-label>
              New Site
              <i class="q-ml-sm fas fa-plus text-muted float-right"></i>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item
          v-if="isAdmin"
          clickable
          class="q-pl-md"
          style="padding-right: 15px"
          to="/users"
          @click="removeOverlay"
        >
          <q-item-section class="text-weight-medium justify-center">
            <q-item-label>
              Users
              <i class="q-ml-sm fa fa-user-friends text-muted float-right"></i>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-separator />
        <q-item clickable class="q-px-md" @click="logout; removeOverlay">
          <q-item-section class="text-weight-medium justify-center">
            <q-item-label>
              Log Out
              <i class="q-ml-sm fa fa-sign-out-alt text-muted float-right"></i>
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-avatar>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import defaultAvatar from '../../assets/images/avatar-default.png'
import sympleApi from '@/api/symple-api'
import { slackNotifier } from '../helpers/helpers.js'

export default {
  name: "AccountMenu",
  props: ['user', 'portfolio', 'connection'],
  setup() {
    const store = useStore()
    let isAdmin = computed(function () {
        return store.state.admin
    });
    return {
      isAdmin,
      store,
      showing: ref(false)
    };
  },
  data() {
    return {
      currentUser: this.user,
      userPortfolio: this.portfolio,
      wsConnection: this.connection
    }
  },
  updated() {
    this.darkStateCheck() //method will execute at pageload
    this.$nextTick(function () {
      $( '#nav-icon, #account' ).click(function(){
        $('#nav-icon').toggleClass('open');
        $('.overlay').toggleClass('open');        
      });
      $('.overlay').click(function(){
        $('#nav-icon').removeClass('open');
        $('.overlay').removeClass('open'); 
      });
      $('.menu-link').click(function(){
        $('#nav-icon, .overlay').removeClass('open');
      });
    });
  },
  mounted() {
    this.darkStateCheck() //method will execute at pageload
    this.$nextTick(function () {
      $( '#nav-icon, #account' ).click(function(){
        $('#nav-icon').toggleClass('open');
        $('.overlay').toggleClass('open');        
      });
      $('.overlay').click(function(){
        $('#nav-icon').removeClass('open');
        $('.overlay').removeClass('open'); 
      });
    });
  },
  methods: {
    logout() {
      if (process.browser) {
        this.wsConnection.send(JSON.stringify({ type: 'Remove Position', image: localStorage.getItem("currentUserImage") }));
        localStorage.removeItem("authToken");
        localStorage.removeItem("currentUserImage");
        localStorage.removeItem("currentUserColor");
      }
    
      this.$router.push({ name: 'Login', params: { logout: true }});

      // CSS Change
      if (document.body.classList.contains('body--dark')) { //when the body has the class 'dark' currently
          document.body.classList.add("body--light");
          document.body.classList.remove("body--dark");
        } 
    },
    createSite() {
      axios
        .post(
          `${sympleApi.sympleApiBaseURL()}/properties`,
          {
            name: 'Untitled'
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        )
        .then((response) => {
          this.$router.push({ name: 'Edit', params: { id: response.data.property.access_id }});
        })
        .catch((error) => {
          slackNotifier(error);
          if (error.response.data.status == 401)
            // HTTP status code 401 = unauthorized
            console.log("Unauthorized.");
          // Redirect to error page
        });
    },
    avatarUrl(users) {
      if(users.attributes.avatar_url)
        return users.attributes.avatar_url
      else
        return defaultAvatar
    },
    toggleDark(){
      var x = document.getElementById("toggle-off");
      var y = document.getElementById("toggle-on");
      var body = document.body;

      if (x.style.display === "none") {
          x.style.display = "block";
          y.style.display = "none";
          console.log("Dark Mode Off");
          body.classList.add("body--light");
          body.classList.remove("body--dark");
        } else {
          x.style.display = "none";
          y.style.display = "block";
          console.log("Dark Mode On");
          body.classList.add("body--dark");
          body.classList.remove("body--light");
        }

        if (document.body.classList.contains('body--dark')) { //when the body has the class 'dark' currently
          localStorage.setItem('darkMode', 'enabled'); //store this data if dark mode is on
        } 
        else {
          localStorage.setItem('darkMode', 'disabled'); //store this data if dark mode is off
        }
      this.removeOverlay();
    },
    darkStateCheck(){
      // On load dark mode check
      var body = document.body

      if(localStorage.getItem('darkMode') == 'enabled'){
        body.classList.add("body--dark");
        body.classList.remove("body--light");

      }
    },
    menuToggleCheck(){
      var x = document.getElementById("toggle-off");
      var y = document.getElementById("toggle-on");
      var body = document.body

      if(localStorage.getItem('darkMode') == 'enabled'){
        body.classList.add("body--dark");
        body.classList.remove("body--light");
        x.style.display = "none";
        y.style.display = "block";
      }
      else {
        x.style.display = "block";
        y.style.display = "none";
      }

    },
    removeOverlay(){
      $('.overlay').removeClass('open');
       $('#nav-icon').removeClass('open');
    }
  },
  watch: {
    connection: function(val) {
      this.wsConnection = val
    }
  }
};
</script>
