<template>
  <div
    class="
      q-pa-lg
      symple-corners
      bg-white
      text-center
      sign-in-block
    "
  >
    <q-form
      @submit="login"
      autocorrect="off"
      autocapitalize="off"
      autocomplete="off"
      spellcheck="false"
      class="justify-center"
    >
      <q-img
        class="q-mb-md items-center"
        src="../../assets/images/logo-dark.svg"
        style="max-width: 300px; height: 100px"
        fit="contain"
        spinner-color="accent"
      />
      <div class="items-center q-px-lg">
        <q-input
          class="symple-corners items-center no-icon login-input"
          placeholder="Email"
          no-error-icon
          outlined
          v-model="email"
          lazy-rules
          :rules="[(val) => val && val.length > 0]"
        />

        <q-input
          class="symple-corners items-center login-input"
          placeholder="Password"
          no-error-icon
          outlined
          type="password"
          v-model="password"
          lazy-rules
          :rules="[(val) => val !== null && val !== '']"
        />

        <div class="row justify-center items-center q-ma-none">
          <div class="col-6 text-left text-weight-medium">
            <q-checkbox v-model="value" color="primary" keep-color size="xs"
              >Remember me
            </q-checkbox>
          </div>
          <div class="col-6 text-right text-weight-medium" color="primary">
            <router-link to="/forgot-password">Forgot Password?</router-link>
          </div>
        </div>
      </div>

      <div class="symple-corners no-shadow">
        <q-btn
          class="login-btn symple-corners q-px-xl q-py-sm q-mt-lg text-white"
          :loading="loading[0]"
          @click="login(), simulateProgress(0)"
          type="submit"
        >
          <span class="text-bold"> Sign In</span>
          <template v-slot:loading>
            <q-spinner class="on-left"></q-spinner>
            <strong>Signing in..</strong>
          </template>
        </q-btn>
      </div>
    </q-form>
  </div>
</template>

<style lang="scss" scoped>
.sign-in-block {
  margin-left: auto;
  margin-right: auto;
  text-align: center !important;
  max-width: 500px;
}
.q-field__control {
  max-width: 80% !important;
}
.login-btn {
  background-color: #00a3ff;
  border: none;
}
.symple-corners {
  border-radius: 5px;
}
.login-btn span {
  font-size: 16px;
}
</style>

<script>
import { Notify } from "quasar";
import axios from "axios";
import { ref } from "vue";
import { useStore } from "vuex";
import sympleApi from '@/api/symple-api'
import { slackNotifier } from '../helpers/helpers.js'

export default {
  setup() {
    const loading = ref([false]);

    const progress = ref(false);

    const store = useStore();

    const loginStore = (token) => store.dispatch("login", token);

    const fetchUser = () => store.dispatch("fetchUser");

    function simulateProgress(number) {
      // we set loading state
      loading.value[number] = true;

      // simulate a delay
      setTimeout(() => {
        // we're done, we reset loading state
        loading.value[number] = false;
      }, 3000);
    }

    return {
      loading,
      progress,
      loginStore,
      fetchUser,
      simulateProgress,
    };
  },

  name: "LoginForm",
  data() {
    return {
      email: "",
      password: "",
      value: false
    };
  },
  mounted() {
    console.log(sympleApi.sympleApiBaseURL())
    if (this.$route.params.logout) {
      Notify.create({
        type: "positive",
        message: "Logged Out!",
        timeout: 1000,
        color: "positive",
      });
    }
    if (this.$route.params.unauthorized) {
      Notify.create({
        type: "negative",
        message: "Please Sign In!",
        timeout: 2000,
        color: "negative",
      });
    }
  },
  methods: {
    submit() {
      axios
        .get(
          `${sympleApi.sympleApiBaseURL()}/auth?email=` +
            this.email +
            "&password=" +
            this.password
        )
        .then((response) => {
          // Store the token from the response, as a cookie.
          if (process.browser) {
            localStorage.setItem("authToken", response.data.token);
            this.$store.dispatch("fetchUser");
            this.setUserSettings()
          }
        })
        .catch((error) => {
          slackNotifier(error);
          Notify.create({
            type: "negative",
            message: "Oops! Your Email or Password is wrong.",
            timeout: 1000,
            color: "negative",
          });
        });
    },
    setUserSettings() {
      const colorPalette = ['#00A4FF', '#401696', '#CACFEA', '#13B990', '#F6CD5A', '#E86868']
      let self = this;
      axios
        .get(`${sympleApi.sympleApiBaseURL()}/users/current/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => {
          let index = Math.abs((parseInt(response.data.data.id) % 7) - 1)
          localStorage.setItem("currentUserColor", colorPalette[index])
          localStorage.setItem("currentUserImage", response.data.data.attributes.avatar_url.url)
          localStorage.setItem("currentUserFirstName", response.data.data.attributes.first_name)
          // 	Now, redirect us to the homepage.
          setTimeout(function(){
            self.$router.push({ name: "Properties", params: { login: true } });
          }, 2000);
        })
        .catch((error) => {
          slackNotifier(error);
        })
    },
    login() {
      try {
        this.submit();
      } catch (error) {
        slackNotifier(error);
        Notify.create({
          type: "negative",
          message: "Oops! Your Email or Password is wrong.",
          timeout: 1000,
          color: "negative",
        });
      }
    },
  },
};
</script>
