export default {
    sympleApiBaseURL() {
        let url;  
        if (process.env.NODE_ENV === 'development') {
            url = `https://api-staging.symple.io/api/v2/`
        } else if (process.env.NODE_ENV === 'localhost') {
            url = `http://localhost:3000/api/v2/`
        } else {
            url = `https://api.symple.io/api/v2/`
        }
        return url
    }
}
