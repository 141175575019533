<template>
  <div class="user-cursor-div" v-for="user in this.users" :key="user.image" :style="this.position(user)">
    <div class="pointer">
      <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.731664 0.269665L22.7139 2.09971C22.9103 2.11606 23.0769 2.24892 23.1352 2.43761C23.195 2.62448 23.1333 2.82945 22.9818 2.95658L16.0209 8.79746L15.5255 17.881C15.5181 18.0185 15.4526 18.1447 15.3507 18.2302C15.3043 18.2692 15.2509 18.2997 15.1917 18.3191C15.0018 18.3834 14.7923 18.3257 14.6639 18.1727L0.312136 1.06898C0.186072 0.918743 0.163396 0.706106 0.255393 0.5309C0.347098 0.355345 0.535942 0.252723 0.731664 0.269665Z" :fill="user.color"/>
        <path d="M1.86824 1.36775L21.3834 2.9924L15.3782 8.03142L15.046 8.31009L15.0224 8.743L14.5973 16.5376L1.86824 1.36775ZM22.6309 3.09626C22.6307 3.09624 22.6304 3.09621 22.6301 3.09619L22.6309 3.09626Z" stroke="#251849" stroke-opacity="0.15" stroke-width="2"/>
      </svg>
    </div>
    <q-avatar class="user-cursor" size="32px">
      <q-img :src="user.image" />
    </q-avatar>
  </div>
</template>
<style>
.user-cursor-div {
  position: absolute !important;
  z-index: 8888;
}

.user-cursor {
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
  margin-right: -6px;
  width: 28px !important;
  height: 28px !important;
  background-color: black;
}

.user-cursor img {
  filter: drop-shadow(0px 7px 10px rgba(0, 0, 0, 0.25));
}

.user-cursor-div .pointer {
  height: 23px;
  width: 30px;
  top: -7px;
  left: -24px;
  position: absolute;
}
</style>
<script>
export default {
  name: "UserCursor",
  props: ['users'],
  methods: {
    position(user) {
      return 'left: ' + user.x + 'px ;top: ' + user.y + 'px;'
    }
  }
};
</script>