<template>
  <q-layout view="hHh lpr fFf">
    <Header :connection="wsConnection" :active-users="users"/>
    <Footer />
    <q-page-container>
      <q-page class="content">
        <!-- page content -->
        <SpecialContainer />
        <!-- End page content -->
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import Header from "../components/header/TheHeader.vue";
import Footer from "../components/footer/TheFooter.vue";
import SpecialContainer from "../components/special/SpecialContainer.vue";

export default {
  name: "Users",
  components: {
    SpecialContainer,
    Header,
    Footer,
  },
  props: ['activeUsers', 'connection', 'wsHandler'],
  data() {
    return {
      users: this.activeUsers,
      wsConnection: this.connection
    }
  }
};
</script>
