import { createApp } from 'vue'
import App from './App.vue'
import $ from 'jquery'
import './styles/quasar.scss'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import '@quasar/extras/material-icons/material-icons.css'
import router from './router'
import { store } from './store/store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './specials-filter'

import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';
// import quasarUserOptions from './quasar-user-options'

createApp(App).use(Quasar, {
    plugins: {
      Notify, 
      Loading
    },
    config: {
      loading: { 
          spinnerColor: "primary",
          backgroundColor: "white",
          customClass: "page-loader"
       }
    }
}).use(router, VueAxios, axios, $).use(store).mount('#app')
