<template>
<div class="q-mx-lg items-center">
  <q-card
    v-for="prop in properties"
    :key="prop.id"
    class="prop-list-card rounded-corners no-shadow q-ma-sm"
    >

    <q-card-section class="q-px-28">
      <div class="text-bold q-pt-sm q-pb-xs row items-center">

        <router-link
          :to="`/properties/${prop.attributes.access_id}/edit`"
          class="col-4 col-md-1"
        >
          <img :src="`${prop.attributes.logo.url}`" class="property-list-img" />
        </router-link>

        <router-link 
          :to="`/properties/${prop.attributes.access_id}/edit`"
          class="col-0 col-md-2 gt-sm">
          <span class="property-title text-dark">
              {{ prop.attributes.name }}
          </span>
          <div class="text-subtitle2 q-pt-none q-pb-sm">
            <span class="card-info">
              {{ prop.attributes.city }}
            </span>
          </div>
        </router-link>

        <router-link  
          :to="`/properties/${prop.attributes.access_id}/edit`"
          class="col-6 col-md-4">
          <!-- publish status full on desktop START-->
          <div class="gt-sm">
            <q-badge v-if="prop.attributes.status === 'Published'" rounded color="positive" :label="prop.attributes.status" class="list-status" />
            <q-badge v-if="prop.attributes.status === 'Processing'" rounded color="warning" :label="prop.attributes.status" class="list-status" />
            <q-badge v-if="prop.attributes.status === 'Not Published'" rounded color="warning" :label="prop.attributes.status" class="list-status" />
            <q-badge v-if="prop.attributes.status == null" rounded color="negative" label="Error" class="list-status" />
          </div>
          <!-- publish status full on desktop END-->
          <span class="property-title text-dark lt-md">
              <a target="_blank" :href="`${prop.attributes.site_url}`"> {{ prop.attributes.name }} </a> 
          <!-- publish status on mobile START-->
          <span class="lt-md q-ml-sm">
            <q-icon v-if="prop.attributes.status === 'Published'" name="fa fa-check-circle" class="text-positive q-pr-xs publish-status" />
            <q-icon v-if="prop.attributes.status === 'Building'" name="fa fa-exclamation-circle" class="text-positive q-pr-xs publish-status" />
            <q-icon v-if="prop.attributes.status === 'Processing'" name="fa fa-exclamation-circle" class="text-warning q-pr-xs publish-status" />
            <q-icon v-if="prop.attributes.status === 'Not Published'" name="fa fa-exclamation-circle" class="text-negative q-pr-xs publish-status" />
          </span>
          <!-- publish status on mobile END-->
          </span>
          <div class="text-subtitle2 q-pt-none q-pb-sm">
            <span class="card-info">
              Last edited 
                <span class="gt-sm">{{ formatDate(prop.attributes.updated_at) }} ({{ timeAgo(prop.attributes.updated_at) }})</span>
                <span class="lt-md">{{ timeAgo(prop.attributes.updated_at) }}</span>
            </span>
          </div>
        </router-link >

        <div class="col-0 col-md-2 gt-sm">
          <a target="_blank" :href="`${prop.attributes.site_url}`">Visit Website <i class="q-pl-xs fas fa-external-link-alt fa-xl"></i></a>
            <span class="text-subtitle2 card-info">
             {{ prop.attributes.site_url }}
            </span>
        </div>
        <q-space />
        <!-- Tile Menu  -->
        <q-btn
          :to="`/properties/${prop.attributes.access_id}/edit`"
          color="transparent"
          icon="fas fa-chevron-right"
          id="property-dropdown"
          for="property-dropdown"
          style="color: #cad0ea !important;"
          size="sm"
        />
        <!-- End Tile Menu  -->
      </div>
    </q-card-section>

  </q-card>
</div>
</template>

<style lang="scss" scoped>
.text-capitalize {
  text-transform: capitalize !important;
}
.q-item-label {
  line-height: normal !important;
}
.default-img {
  background-image: url("../../assets/images/img-default.png");
  background-size: cover;
  background-position: center;
  &:hover {
    background-image: url("../../assets/images/img-default.png");
    background-size: cover;
    background-position: center;
  }
}
.q-img__content > div {
  padding: 0 !important;
}
.card-info {
  color: #636d70 !important;
}
.property-title {
  font-size: 16px;
}
</style>

<script>
import axios from "axios";
import ClipboardJS from "clipboard";
import { computed } from "vue";
import { useStore } from "vuex";
import sympleApi from "@/api/symple-api";
import { slackNotifier } from "../helpers/helpers.js";
import moment from 'moment'
new ClipboardJS("#access-id-btn");
new ClipboardJS("#access-token-btn");

export default {
  name: "PropertyTile",

  props: ["connection", "propertiesList"],

  setup() {
    const store = useStore();
    let isAdmin = computed(function () {
      return store.getters.isAdmin;
    });
    return {
      isAdmin,
      store,
    };
  },

  data() {
    return {
      properties: this.propertiesList,
      wsConnection: this.connection
    };
  },

  watch: {
    connection: function (val) {
      this.wsConnection = val;
    },
    properties: function () {
      // let self = this;
      // var properties = JSON.parse(JSON.stringify(value))
      // this.wsConnection.onmessage = function(event) {
      // var data = JSON.parse(event.data)
      // if (data.type === 'Publish Status') {
      // var property = properties.filter(x => x.attributes.access_id === data.access_id)[0]
      // self.$parent.$emit('handleWebhook', { message: JSON.parse(event.data), value: property.attributes })
      // }
      // }
    },
  },

  methods: {
    websocket() {
      if (process.env.NODE_ENV === "development") {
        return "ws://localhost:8080";
      } else {
        return "wss://symple.io:8080";
      }
    },
    duplicateProperty(property) {
      axios
        .post(
          `${sympleApi.sympleApiBaseURL()}/properties/` +
            property.attributes.access_id +
            "/duplicate",
          "",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        )
        .then(() => {
          this.getProperties();
        })
        .catch((error) => {
          slackNotifier(error);
        });
    },
    deleteProperty(property) {
      axios
        .delete(
          `${sympleApi.sympleApiBaseURL()}/properties/` +
            property.attributes.access_id,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        )
        .then(() => {
          this.getProperties();
        })
        .catch((error) => {
          slackNotifier(error);
        });
    },
    formatLocation(property) {
      return property.attributes.city == undefined
        ? ""
        : property.attributes.city;
    },
    formatDate(oldDate) {
      if (oldDate) {
        var options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        };
        let newDate = new Date(oldDate).toLocaleDateString("en-US", options);
        return newDate;
      } else {
        return "";
      }
    },
    timeAgo(editDate) {
      return moment(editDate).fromNow();
    }
  },
};
</script>
