<template>
  <div
    class="
      col-xs-9 col-sm-6 col-lg-4 col-xl-4
      q-pa-lg
      symple-corners
      bg-white
      text-center
    "
  >
    <q-form
      @submit.prevent="handleSubmit"
      autocorrect="off"
      autocapitalize="off"
      autocomplete="off"
      spellcheck="false"
      class="justify-center"
    >
      <router-link to="/">
        <q-img
          class="q-mb-md items-center"
          src="../../assets/images/logo-dark.svg"
          style="max-width: 300px; height: 100px"
          fit="contain"
          spinner-color="accent"
      /></router-link>
      <p>
        Reset your password
      </p>
      <div class="items-center q-px-lg">
        <q-input
          class="symple-corners items-center"
          placeholder="Code"
          no-error-icon
          outlined
          v-model="token"
          lazy-rules
          :rules="[
            (val) => (val && val.length > 0),
          ]"
        />
        <q-input
          class="symple-corners items-center"
          placeholder="New Password"
          no-error-icon
          outlined
          v-model="password"
          lazy-rules
          :rules="[
            (val) => (val && val.length > 0),
          ]"
        />
      </div>

      <div class="symple-corners no-shadow">
        <q-btn
          class="login-btn symple-corners q-px-xl q-py-sm q-mt-lg text-white"
          type="submit"
        >
          <span class="text-bold"> Update Password </span>

        </q-btn>
      </div>
    </q-form>
  </div>
</template>

<style lang="scss" scoped>
.q-field__control {
  max-width: 80% !important;
}
.login-btn {
  background-color: #00a3ff;
  border: none;
}
.symple-corners {
  border-radius: 5px;
}
</style>

<script>

// import { ref } from "vue";
import axios from 'axios';
import sympleApi from '@/api/symple-api';
import { Notify } from "quasar";

export default {
  
  //   setup() {
  //   const loading = ref([false]);
  //   const progress = ref(false);
  //   function simulateProgress(number) {
  //     // we set loading state
  //     loading.value[number] = true;
  //     // simulate a delay
  //     setTimeout(() => {
  //       // we're done, we reset loading state
  //       loading.value[number] = false;
  //     }, 3000);
  //   }
  //   return {
  //     loading,
  //     progress,
  //     simulateProgress,
  //   };
  // },

  name: "PasswordReset",

  data() {
    return {
      token: "",
      password: ""
    };
  },
  created() {
    this.token = this.$route.query.token
  },
  methods: {
    async handleSubmit() {
      
      const response = await axios.post(`${sympleApi.sympleApiBaseURL()}password_reset?password_reset_token=` + this.token + `&password=` + this.password);
      
      if (response) {
          Notify.create({
          type: "positive",
          message: "Password Reset!",
          timeout: 1000,
          color: "positive",
          });
        }

        else {
          Notify.create({
          type: "negative",
          message: "An error occurred while resetting your password.",
          timeout: 1000,
          color: "negative",
          });
        }
    }

  }
};

</script>
