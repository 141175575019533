import { createRouter, createWebHistory } from 'vue-router'
import { store } from '../store/store.js'
// 1. Define route components.
// These can be imported from other files
import Login from '../views/Login.vue'
import Properties from '../views/Properties.vue'
import Edit from '../views/Edit.vue'
import Users from '../views/Users.vue'
import ForgotPassword from "../views/ForgotPassword.vue"
import PasswordReset from "../views/PasswordReset.vue"
import Specials from '../views/Specials.vue'
import Settings from '../views/Settings.vue'

function websocket() {
    if (process.env.NODE_ENV === 'development') {
      return "ws://localhost:8080";
    } else if (window.location.href.indexOf("beta") > -1) {
      return "wss://beta.symple.io:8080";
    } else {
      return "wss://symple.io:8080";
    }
}

// 2. Define some routes
// Each route should map to a component.
const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
        beforeEnter: (a, x, next) => {
            if (localStorage.getItem("authToken")) next({ name: 'Properties' })
            else next()
        }
    },
    {
        path: '/forgot-password',
        name: 'Forgot Password',
        component: ForgotPassword
    },
    {
        path: '/reset-password',
        name: 'Password Reset',
        component: PasswordReset,
        children: [
            // UserHome will be rendered inside User's <router-view>
            // when /user/:id is matched
            { path: '', component: PasswordReset }
    
            // ...other sub routes
          ]
    },
    {
        path: '/properties',
        name: 'Properties',
        component: Properties,
        beforeEnter: (a, x, next) => {
            if (!localStorage.getItem("authToken")) next({ name: 'Login', params: { unauthorized: true }})
            else {
                next()
            }
        }
    },
    {
        path: '/properties/:id/edit',
        name: 'Edit',
        component: Edit,
        beforeEnter: (a, x, next) => {
            if (!localStorage.getItem("authToken")) next({ name: 'Login', params: { unauthorized: true }})
            else next()
        }
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        beforeEnter: (a, x, next) => {
            if (!localStorage.getItem("authToken")) next({ name: 'Login', params: { unauthorized: true }})
            else next()
        }
    },
    {
        path: '/specials',
        name: 'Specials',
        component: Specials,
        beforeEnter: (a, x, next) => {
            if (!localStorage.getItem("authToken")) next({ name: 'Login', params: { unauthorized: true }})
            else next()
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        beforeEnter: (a, x, next) => {
            if (!localStorage.getItem("authToken")) next({ name: 'Login', params: { unauthorized: true }})
            else next()
        }
    }
]

// 3. Create the router instance and pass the `routes` option
const router = createRouter({
    // 4. Provide the history implementation to use. 
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if(localStorage.getItem("authToken") && !Object.keys(store.getters.getUser).length>0) {
        store.dispatch("fetchUser").then(() => {
            if(Object.keys(store.getters.getUser).length===0) {
                next({ name: 'Login', params: { unauthorized: true }})
            }
            else next()
        })
    }
    let connection = new WebSocket(websocket())
    connection.onpen = function(event) {
        event.target.send(JSON.stringify({ type: 'Remove User Field Focus', image: localStorage.getItem("currentUserImage"), color: localStorage.getItem("currentUserColor") }));
    }
    next()
})

export default router;